
import { defineComponent, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { NewApplicationUser } from "../../../core/types/UserTypes";
import { NewRespondentClientDto } from "../../../core/types/RespondentTypes";

export default defineComponent({
  name: "new-client-user-modal",
  props: ["clientId"],
  components: { Field, Form, ErrorMessage },
  setup(props) {
    const store = useStore();

    const submitButton = ref<HTMLElement | null>(null);

    const modalRef = ref<null | HTMLFormElement>(null);
    const clientInfo = computed(() => {
      return store.getters.getClientInfo(Number(props.clientId));
    });
    const newApplicationUser = ref<NewApplicationUser>({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      userRole: "",
      licensedDistributor: false,
      activeAccount: true,
      hireStatusValue: 0,
      companyName: "",
      jobTitle: "",
    });

    const newRespondentClient = ref<NewRespondentClientDto>({
      aspNetUserId: "",
      clientId: props.clientId,
      clientLocationId: 0,
    });

    const registration = Yup.object().shape({
      firstName: Yup.string().required().label("First Name"),
      lastName: Yup.string().required().label("Last Name"),
      email: Yup.string().min(4).required().email().label("Email"),
      jobTitle: Yup.string().required().label("Job Title"),
      password: Yup.string().min(6).required().label("Password"),
      confirmPassword: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
      userRole: Yup.string().required().label("User Role"),
      hireStatus: Yup.number()
        .min(5, "Hire Status is required")
        .required()
        .label("Hire Status"),
      location: Yup.number()
        .min(1, "Location is required")
        .required()
        .label("Location"),
    });

    // Get Hire Status Enums
    const hiringStatuses = computed(() => {
      return store.getters.getHiringStatuses;
    });
    store
      .dispatch(Actions.GET_INCLINE_HIRE_STATUS_ENUMS)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving hiring statuses.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const onSubmitRegister = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      newApplicationUser.value.companyName = clientInfo.value.name;
      // Send login request
      store
        .dispatch(Actions.CREATE_USER, newApplicationUser.value)
        .then((payload) => {
          newRespondentClient.value.aspNetUserId = payload.id;
          store
            .dispatch(
              Actions.CREATE_RESPONDENT_CLIENT,
              newRespondentClient.value
            )
            .then(() => {
              Swal.fire({
                text: "Success. The new Client User has been created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Continue",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                resetForm();
                modalRef.value?.click();
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText:
                  "There was an issue creating the Client User. Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was an issue creating the account. Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    const userRoles = computed(() => {
      if (
        store.getters.currentUserRole === "SuperAdmin" ||
        store.getters.currentUserRole === "LicensedDistributor" ||
        store.getters.currentUserRole === "LDAssociate" ||
        store.getters.currentUserRole === "PowerUser"
      ) {
        return [
          { roleName: "PowerUser", roleValue: "PowerUser" },
          { roleName: "ClientUser", roleValue: "ClientUser" },
        ];
      }
      return [];
    });

    const locations = computed(() => {
      return store.getters.getClientLocations(Number(props.clientId));
    });

    function closeModal() {
      modalRef.value?.click();
      resetForm();
    }

    function resetForm() {
      newApplicationUser.value = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        userRole: "",
        licensedDistributor: false,
        activeAccount: true,
        hireStatusValue: 0,
        companyName: "",
        jobTitle: "",
      };
    }

    return {
      newApplicationUser,
      clientInfo,
      newRespondentClient,
      hiringStatuses,
      closeModal,
      resetForm,
      modalRef,
      userRoles,
      locations,
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
});
