<template>
  <!--begin::Modal - New Target-->
  <div
    class="modal fade"
    id="modal_new_client_user"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            ref="modalRef"
            @click="closeModal()"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
          <!--begin:Form-->
          <Form
            class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
            novalidate="novalidate"
            @submit="onSubmitRegister"
            id="new_client_user_form"
            :validation-schema="registration"
          >
            <!--begin::Heading-->
            <div class="mb-13 text-center">
              <!--begin::Title-->
              <h1 class="mb-3">Create New User</h1>
              <!--end::Title-->
            </div>
            <!--end::Heading-->

            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">First Name</label>

                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder="first name"
                  name="firstName"
                  autocomplete="off"
                  v-model="newApplicationUser.firstName"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="firstName" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">Last Name</label>

                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder="last name"
                  name="lastName"
                  autocomplete="off"
                  v-model="newApplicationUser.lastName"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="lastName" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">Email</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="email"
                placeholder="email"
                name="email"
                autocomplete="off"
                v-model="newApplicationUser.email"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="email" />
                </div>
              </div>
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">Job Title</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="job title"
                name="jobTitle"
                autocomplete="off"
                v-model="newApplicationUser.jobTitle"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="jobTitle" />
                </div>
              </div>
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">Password</label>

                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="password"
                  placeholder=""
                  name="password"
                  autocomplete="off"
                  v-model="newApplicationUser.password"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="password" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2"
                  >Confirm Password</label
                >
                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="password"
                  placeholder=""
                  name="confirmPassword"
                  autocomplete="off"
                  v-model="newApplicationUser.confirmPassword"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="confirmPassword" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">User Role</label>
                <Field
                  name="userRole"
                  class="form-select form-select-solid"
                  as="select"
                  v-model="newApplicationUser.userRole"
                >
                  <option value="">Select a Role...</option>
                  <option
                    v-for="item in userRoles"
                    :key="item.roleValue"
                    :label="item.roleName"
                    :value="item.roleValue"
                  >
                    {{ item.roleName }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="userRole" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">Hire Status</label>
                <Field
                  name="hireStatus"
                  class="form-select form-select-solid"
                  as="select"
                  v-model="newApplicationUser.hireStatusValue"
                >
                  <option value="0">Select a Status...</option>
                  <option
                    v-for="status in hiringStatuses"
                    :key="status.value"
                    :label="status.text"
                    :value="status.value"
                  >
                    {{ status.text }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="hireStatus" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">Location</label>
                <Field
                  name="location"
                  class="form-select form-select-solid"
                  as="select"
                  v-model="newRespondentClient.clientLocationId"
                >
                  <option value="0">Select a Location...</option>
                  <option
                    v-for="item in locations"
                    :key="item.clientLocationId"
                    :label="item.locationName"
                    :value="item.clientLocationId"
                  >
                    {{ item.locationName }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="location" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Actions-->
            <div class="text-center">
              <button
                type="reset"
                id="kt_modal_new_client_user_cancel"
                class="btn btn-white me-3"
                data-bs-dismiss="modal"
                @click="resetForm()"
              >
                Cancel
              </button>

              <button
                id="kt_sign_up_submit"
                ref="submitButton"
                type="submit"
                class="btn btn-lg btn-primary"
              >
                <span class="indicator-label"> Submit </span>
                <span class="indicator-progress">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Target-->
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { NewApplicationUser } from "../../../core/types/UserTypes";
import { NewRespondentClientDto } from "../../../core/types/RespondentTypes";

export default defineComponent({
  name: "new-client-user-modal",
  props: ["clientId"],
  components: { Field, Form, ErrorMessage },
  setup(props) {
    const store = useStore();

    const submitButton = ref<HTMLElement | null>(null);

    const modalRef = ref<null | HTMLFormElement>(null);
    const clientInfo = computed(() => {
      return store.getters.getClientInfo(Number(props.clientId));
    });
    const newApplicationUser = ref<NewApplicationUser>({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      userRole: "",
      licensedDistributor: false,
      activeAccount: true,
      hireStatusValue: 0,
      companyName: "",
      jobTitle: "",
    });

    const newRespondentClient = ref<NewRespondentClientDto>({
      aspNetUserId: "",
      clientId: props.clientId,
      clientLocationId: 0,
    });

    const registration = Yup.object().shape({
      firstName: Yup.string().required().label("First Name"),
      lastName: Yup.string().required().label("Last Name"),
      email: Yup.string().min(4).required().email().label("Email"),
      jobTitle: Yup.string().required().label("Job Title"),
      password: Yup.string().min(6).required().label("Password"),
      confirmPassword: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
      userRole: Yup.string().required().label("User Role"),
      hireStatus: Yup.number()
        .min(5, "Hire Status is required")
        .required()
        .label("Hire Status"),
      location: Yup.number()
        .min(1, "Location is required")
        .required()
        .label("Location"),
    });

    // Get Hire Status Enums
    const hiringStatuses = computed(() => {
      return store.getters.getHiringStatuses;
    });
    store
      .dispatch(Actions.GET_INCLINE_HIRE_STATUS_ENUMS)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving hiring statuses.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const onSubmitRegister = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      newApplicationUser.value.companyName = clientInfo.value.name;
      // Send login request
      store
        .dispatch(Actions.CREATE_USER, newApplicationUser.value)
        .then((payload) => {
          newRespondentClient.value.aspNetUserId = payload.id;
          store
            .dispatch(
              Actions.CREATE_RESPONDENT_CLIENT,
              newRespondentClient.value
            )
            .then(() => {
              Swal.fire({
                text: "Success. The new Client User has been created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Continue",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                resetForm();
                modalRef.value?.click();
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText:
                  "There was an issue creating the Client User. Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was an issue creating the account. Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    const userRoles = computed(() => {
      if (
        store.getters.currentUserRole === "SuperAdmin" ||
        store.getters.currentUserRole === "LicensedDistributor" ||
        store.getters.currentUserRole === "LDAssociate" ||
        store.getters.currentUserRole === "PowerUser"
      ) {
        return [
          { roleName: "PowerUser", roleValue: "PowerUser" },
          { roleName: "ClientUser", roleValue: "ClientUser" },
        ];
      }
      return [];
    });

    const locations = computed(() => {
      return store.getters.getClientLocations(Number(props.clientId));
    });

    function closeModal() {
      modalRef.value?.click();
      resetForm();
    }

    function resetForm() {
      newApplicationUser.value = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        userRole: "",
        licensedDistributor: false,
        activeAccount: true,
        hireStatusValue: 0,
        companyName: "",
        jobTitle: "",
      };
    }

    return {
      newApplicationUser,
      clientInfo,
      newRespondentClient,
      hiringStatuses,
      closeModal,
      resetForm,
      modalRef,
      userRoles,
      locations,
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
});
</script>
